import React from 'react'
import "./test.css"
import test from '../assets/testimonialHero.png'
import {Swiper , SwiperSlide } from "swiper/react"
import "./test.css"
import {TestimonialsData} from '../data/testimonials'
import { Pagination, Navigation } from 'swiper'
import 'swiper/css/pagination';
import 'swiper/css';



const Test = () => {
  return (
    <div>
     <div className="container testimonial">
        <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12  test-l">
                <span>TOP RATED</span>
                 <p>SEEDILY SAY HAS SUITABLE DISPOSAL AND BOY.<br/> EXERCISE JOY MAN CHILDREN REJOICED.</p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 test-c">
                <img src={test} alt="" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 test-r">
                <span>100K</span>
                <span>HAPPY CUSTOMERS WITH US</span>
            </div>
        </div>
     </div>

     <div className="container ">
        <h1 className='text-center'>REVIEWS</h1>
        <div className="carousle">
          <Swiper
        
          spaceBetween={30}
          slidesPerView={3}
          slidesPerGroup={3}
        loop={true}
       
        breakpoints={{
          768:{
            slidesPerView:3
          },
          640:{
            slidesPerView:2
          },
          0:{
            slidesPerView:1
          }
        }}
        
          >
          {TestimonialsData.map((person)=>(
            <SwiperSlide>
                <div className="profile">
                    <img src={person.image} alt="" />
                    <span>{person.comment}</span>
                    <span>{person.name}</span>
                </div>
            </SwiperSlide>

           ))}
          </Swiper>
        </div>
     </div>
    </div>
  )
}

export default Test
