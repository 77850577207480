import "./App.css";
import React from "react";
import Header from "./components/Header"
import Hero from "./components/hero";
import Compare from "./components/compare";
import Products from "./components/products";
import Test from "./components/test";
import Footer from './components/footer'

function App() {
  return (
    <div className="App">
      <Header/>
      <Hero/>
      <Compare/>
      <Products/>
      <Test/>
      <Footer/>
    </div>
  );
}

export default App;
