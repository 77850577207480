import React, { useState } from 'react'
import "./Header.css"
import logo from '../assets/logo.png'

const Header = () => {
    const mobile = window.innerWidth<=800 ? true : false;
    const [openMenu , setOpenMenu] = useState(false);
  return (
    <>
        <nav>
        <div className="header-l">
            <img src={logo} alt="" />
            <span>Mr Siraj Store</span>
        </div>
        {openMenu=== false && mobile == true ? (
                     <div  onClick={(()=>setOpenMenu(true))}>
                     <i className="bi bi-list" style={{fontSize:"2rem", cursor:"pointer"}}></i>
                     </div>
            ):(
                <div className="header-r">
                <ul>
                    <li onClick={(()=> setOpenMenu(false))}>Collections</li>
                    <li onClick={(()=> setOpenMenu(false))}>Brands</li>
                    <li onClick={(()=> setOpenMenu(false))}>New</li>
                    <li onClick={(()=> setOpenMenu(false))}>Sales</li>
                    <li onClick={(()=> setOpenMenu(false))}>Eng</li>
                    <input type="search" placeholder='Search'/>
                <span><i onClick={(()=> setOpenMenu(false))} className="bi bi-cart"></i></span>
    
                </ul>
          
            </div>
            )}
      
        </nav>
    </>
  )
}

export default Header
