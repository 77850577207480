import React from 'react'
import "./hero.css"
import hero from "../assets/hero.png"
import {Swiper , SwiperSlide } from "swiper/react"
import {Navigation, Pagination} from "swiper"
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {SliderProducts} from "../data/products"
import { motion } from 'framer-motion'

const Hero = () => {
    const transition={duration:3, type:"spring"}
  return (
    <div>
      
        {/* hero section */}

        <div className="container hero ">
            <div className="row ">
                <div className="col-lg-3 col-md-3 col-sm-12 hero-L">
                    <div className='top'>
                    <span>Skin <br/> </span>
                    <span>Protection <br/> </span>
                    <span>Cream</span>
                    </div>

                    <div className='bottom'>
                    <span>Trendy <br/>Collection </span>
                    <p>Seedily say has suitable disposal and boy.<br/> Exercise joy man children rejoiced.</p>
                    </div>
                   

                </div>
                <div className="col-lg-6 col-md-6 col-sm-12  hero-C">
                    <div></div>
                    <motion.img 
                    //   initial={{width:"30%"}}
                    //   whileInView={{width:"70%"}}
                    //   transition={transition}
                    src={hero} alt="Image" />
                    <div className='Float'>
                    <motion.i
                      initial={{x:"30px"}}
                      whileInView={{x:"0px"}}
                      transition={transition}
                    className="bi bi-basket3-fill"></motion.i>
                    <motion.div
                      initial={{y:"30px"}}
                      whileInView={{y:"0px"}}
                      transition={transition}
                    >
                   
                    <span>Best Signup<br/> Offers</span>
                    <i className="bi bi-arrow-right-circle"></i>

                    </motion.div>
                    </div>
                    
                </div>
                
                <div className="col-lg-3 col-md-3 col-sm-12  hero-R">
                    <div className='top'>
                    <span>1.5m <br/></span>
                    <span>Monthly Traffic </span>
                    </div>
                   
                    <div className='bottom'>
                    <span>100K <br/> </span>
                    <span>Happy Customers</span>
                    </div>
                   
                </div>
            </div>
        </div>



{/* carousle */}

      <div className=" Slider">
       <Swiper 
        modules={[Navigation, Pagination]}
       spaceBetween={30}
       slidesPerView={3}
       slidesPerGroup={3}
       loop={true}
       navigation
       pagination={{ clickable: true }}
       breakpoints={{
        768:{
          slidesPerView:3
        },
        640:{
          slidesPerView:2
        },
        0:{
          slidesPerView:1
        }
      }}
       >

        {SliderProducts.map((slide , i)=>(
              <SwiperSlide>
             
            <div className="left-s">
                    <div className="name">
                        <span>{slide.name}<br/> </span>
                        <span>{slide.detail}</span>
                    </div>
                    <span>{slide.price}$</span>
                   <div>Shop Now</div>
                </div>
              
                    <img src={slide.img} alt=""  className='img-p'/>
              
          
              </SwiperSlide>     
        ))}
        
     
       
      
       </Swiper>
       </div>
      
    </div>
  )
}

export default Hero

