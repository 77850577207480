import React from 'react'
import "./footer.css"
import logo from '../assets/logo.png'

const Footer = () => {
  return (
    <div className='f'>
      < hr />
        <div className="container footer">
     
            <div className="row f-con">
                <div className="col-md-3 col-lg-3  col-sm-6 col-12">
                    <img src={logo} alt="" />
                 
                </div>
                <div className="col-md-3 col-lg-3 col-sm-6 col-12 contact">
                    <h3>Contact Us</h3>
                    <span><i class="bi bi-geo-alt"></i> 111 north avenue Orlando, FL 32801</span><br/>
                    <span><i class="bi bi-telephone"></i> 352-306-4415</span><br/>
                    <span><i class="bi bi-envelope"></i> sadiqminhas87@gmail.com</span>

                </div>
                <div className="col-md-3 col-lg-3 col-sm-6 col-12 account">
                    <h3>Account</h3>
                    <span><i class="bi bi-box-arrow-in-right"></i>Sign In</span>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-6 col-12 company">
                    <h3>Company</h3>
                    <span><i class="bi bi-person-check"></i>About Us</span>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Footer
