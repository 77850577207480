import React from 'react'
import './compare.css'
import shade from "../assets/shade.png"
import ReactCompareImage from 'react-compare-image'
import Before from "../assets/before.png"
import After from "../assets/after.png"

const Compare = () => {
  return (
    <div>
        <div className="container compare">
            <div className="row">
                <div className="col-md-6 col-lg-6 col-sm-12 left-c">
                  <div>
                    <h2>VIRTUAL TRY-ON</h2>
                    <p>NEVER BUY THE WRONG <br/>SHADE AGAIN!</p>
                    <h3>TRY NOW!</h3>
                    <img src={shade} alt="" />
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-12">
                    <div className="wrapper">
                    <ReactCompareImage leftImage={Before} rightImage={After} />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Compare
