import React from 'react'
import { useState } from 'react'
import plane from "../assets/plane.png"
import {ProductsData } from "../data/products"
import "./product.css"

const Products = () => {
    const [menu, setMenu] = useState(ProductsData)
    const filter =(type)=>{
        setMenu(ProductsData.filter((product)=> product.type === type));
    }
  return (
    <div>
      <div className="container productGal">

        <img src={plane} alt="" />
        <h1>Our Feature Products</h1>
        <div className="products">
            <ul className="menu">
                <li onClick={(()=> setMenu(ProductsData))}>All</li>
                <li onClick={(()=> filter("skin care"))}>Skin Care</li>
                <li onClick={(()=> filter("conditioner"))}>Conditioner</li>
                <li onClick={(()=> filter("foundation"))}>Foundation</li>
            </ul>
            <div className="list">
                 
                        {menu.map((product)=>(
                           <>
                              <div className="product">
                            <div className="left-s">
                    <div className="name">
                        <span>{product.name}<br/> </span>
                        <span>{product.detail}</span>
                    </div>
                    <span>{product.price}$</span>
                   <div>Shop Now</div>
                  
                </div>
                <img src={product.img} alt=""  className='img-q'/>
                   
                    </div>
                           </>
                        ))}
                    </div>
            </div>
        </div>
      </div>
  
  )
}

export default Products
